$primary: #ca9008;
$secondary: #395a7d;

aside.ant-layout-sider.ant-layout-sider-light {
  background-image: url("/images/sidebar.png") !important;
}

.dashboard{
    padding: 20px;
    &__col{
      width: 100%;
    }
}
.item {
  &__card {
    display: flex;
    justify-content: center;
    &__image {
      background-color: $primary;
      padding: 10px;
      border-radius: 30px;
    }
    &__title {
      font-size: 16px;
      margin-top: 12px;
      color: $primary;
    }
    &__amount {
      font-size: 12px;
      color: $primary;
    }
    .ant-card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
