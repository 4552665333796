$primary: #ca9008;
$secondary: #395a7d;

.form-control{
    height: 42px!important;
    border-radius: 5px!important;
}
select.form-control{
    height: 42px!important;
    border-radius: 5px!important;
    // -moz-appearance:none; /* Firefox */
    // -webkit-appearance:none; /* Safari and Chrome */
    // appearance:none;
    right: 11px;
}
.form-control:focus {
  border-color: $primary;
  box-shadow: 0 0 0 0.1rem rgb(247, 227, 181);
}

.btn-success {
  border-color: $primary;
  background: $primary !important;
  
  &:focus{
    box-shadow:none!important
  }
  &:focus-visible{
    outline: none!important;
  }
}

.customStatus{
    height: 22px;
    font-size: 10px!important;
}

.customCard input.btn.btn-success{
    margin-top: 33px!important;
}

.panel.panel-default  input.btn.btn-success{
    margin-top: 33px!important;

}


.btn-danger {
    border-color: $primary!important;
    color: $primary!important;
    &:focus{
      box-shadow:none!important
    }
    &:focus-visible{
      outline: none!important;
    }
  }

 .ant-page-header-heading-extra{
    a.btn.btn-secondary{
        background: $primary!important;
        &:focus{
            box-shadow: none!important;
        }
    }
    a.btn.btn-primary{
        background: $primary!important;
        &:focus{
            box-shadow: none!important;
        }
    }
  }
  