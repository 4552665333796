.customCard{
    /*box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19)*/
    /*box-shadow: 0 1px 1px rgba(0,0,0,.05);*/
}
.customCard .card-header{
    background-color: #ddd;
    border-radius: 5;
    /*border-bottom: 1;*/
}
.customTable .customCenter{
    text-align: center !important;
}
.customStatus {
    min-width: 72px;
    border-radius: 8px;
    font-size: 13px !important;

}
.customAction {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50% !important;
    font-size: 15px;
}

/*.btn-primary {*/
/*    color: #fff;*/
/*    padding: 8px;*/
/*    font-size: 13px;*/
/*}*/

.btn-secondary{
    color: #fff;
    background-color: #3c6382;
    border-color: #3c6382;
    padding: 8px;
    font-size: 13px;
}

.btn-secondary:hover{
    color: #fff;
    background-color: #3c6382;
    border-color: #3c6382;
}

/*.btn-success {*/
/*    color: #fff;*/
/*    padding: 8px;*/
/*    font-size: 13px;*/
/*}*/



.btn-danger{
    color: #fff;
    background-color: #c30303; 
    padding: 2px  0 2px 0 !important;
    width: 80px;
    /* outline: none !important; */
}
.btn-danger:focus{
    outline: none !important;
}
.btn-dang{
    color: #fff;
    padding: 2px  0 2px 0 !important;
    width: fit-content;
    border-color: #ca9008 !important;
}
.btn-dang{
    outline: none !important;  
}
.btn-running{
    color: #fff;
    background-color: #3c6382; 
    padding: 2px  0 2px 0 !important;
    outline: none !important;
}
.btn-running:hover {
    color: #fff; /* Change text color to black on hover */
}
.customAction span.fa.fa-undefined.fa.fa-pencil {
    font-size: 13px;
    color: #ecf0f1;
}

.customAction span.fa.fa-undefined.fa.fa-check {
    font-size: 13px;
    color: #036303 !important;
   
    
}
.customAction span.fa.fa-undefined.fa.fa-ban {
    font-size: 13px;
    color: #333333 !important;
   
    
}
.customAction span.fa.fa-undefined.fa.fa-trash {
    font-size: 16px;
    color: #ff0019 !important;
   
    
}

.fcYNBJ .ant-card-head .ant-card-head-title {
    padding: 10px 0;
}

.dTifBL .ant-card-body {
    padding: 10px 25px 25px 25px !important;
}
.fCPmtk a i, .fCPmtk a span.fa {
    font-size: 16px;
    color: #ffffff;
}
.ant-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum";
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 5px 10px;
    color: #272B41;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #E3E6EF;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.ant-page-header-heading-extra {
    margin: 0px 0;
    white-space: nowrap;
}

 .ant-layout-header {
     /*background-color: #0221BA !important;*/
     /*background-color: #051c8c !important;*/
     /* background-color: #9a672a !important; */
 }

.ant-layout-header .collapse-btn {
    background: none;
    border: none;
}

.ant-layout-header .collapse-btn:hover {
    background: none;
    border: none;
}

.ant-page-header {
    padding: 15px 30px 15px 30px !important;
}
.dTifBL .sDash_form-action button {
    border-radius: 4px;
    padding: 6.4px 29.2px;
    height: 38px;
}

/*li.ant-menu-submenu.ant-menu-submenu-vertical {*/
/*    display: block !important;*/
/*}*/


.gXNDpq {
    background: #2C99FF;
    border-width: 0px;
    border-style: solid;
    color: #ffffff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0px 20.5px;
    height: 35px;
    font-weight: 500;
    box-shadow: 0 0;
}
.boXhL {
    background: #5F63F2;
    border-width: 0px;
    border-style: solid;
    color: #ffffff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0px 20.5px;
    height: 35px;
    font-weight: 500;
    box-shadow: 0 0;
}
.ant-page-header {
    padding: 18px 30px 18px 30px !important;
}
.table td, .table th {
    padding: 8px;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.btn-group-sm>.btn, .btn-sm {
    padding: 4px 4px;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

/*.text-scrolling{*/
/*    color: #aa2233;*/
/*    position: relative;*/
/*    !*margin-right: 10000px;*!*/
/*    z-index: 3;*/
/*    top: -300px;*/
/*    animation: myfirst 10s linear 2s infinite alternate;*/
/*}*/

/*@keyframes myfirst {*/
/*    0%   {left: 0px; top: 0px;}*/
/*    50%  {left: 1100px; top: 0px;}*/
/*    100% {left: 0px; top: 0px;}*/
/*}*/

/*.text-scrolling {*/
/*    float: right;*/
/*    color:#eb2f96;*/
/*    animation: blinker 1s linear infinite;*/
/*}*/

/*@keyframes blinker {*/
/*    50% {*/
/*        opacity: 0;*/
/*        color: #000c17;*/
/*    }*/
/*}*/

/*.add_button{*/
/*    padding: 8px;*/
/*    font-size: 13px;*/
/*}*/

/*.add_button a{*/
/*    color: #fff;*/
/*}*/

/*.list_button{*/
/*    padding: 8px;*/
/*    font-size: 13px;*/
/*}*/

.sDash_form-action button{
    padding: 8px;
    font-size: 13px;
}

input.btn.btn-success{
    padding: 0 8px !important;
    font-size: 13px !important;
    margin-top: 26px !important;
}
.btn-succes:focus{
    outline: none !important;
}
input{
    height: 30px !important;
    font-size: 15px !important;
}
select.form-control {
    height: 30px !important;
    font-size: 15px !important;
}
textarea.form-control{
    /*height: 50px !important;*/
    font-size: 15px !important;
}

textarea.form-control.message{
    height: 150px !important;
}

.ant-form-item-has-error .ant-picker {
    border-color: #f5222d !important;
}

.ant-input-picker{
    margin: 3px 10px !important;
}

.login-form-signup {
    float: left;
    color: #092d4b;
    padding: 0;
    margin: 0;
}